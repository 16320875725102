import { useState, useEffect, useRef } from "react";
import { size } from "lodash";
import fetchPastResults from "lib/data/fetchPastResults";
import { useUpdateUser } from "lib/user/context";

const usePastResults = (user) => {
  const updateUser = useUpdateUser();

  const isMounted = useRef(true);

  const [results, setResults] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const results = await fetchPastResults(user.id, user.country);
      if (isMounted.current) setResults(results);
    };

    if (user) fetchData();
  }, [user]);

  const count = size(results);

  useEffect(() => {
    if (user) {
      const { resultsCount = null } = user;

      if (resultsCount === null || resultsCount < count) {
        updateUser({ ...user, resultsCount: count });
      }
    }
  }, [count, user]);

  // Set isMounted to false to prevent state update on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return results;
};

export default usePastResults;
