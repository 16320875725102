import React from "react";
import Link from "next/link";
import styled from "styled-components";
import { Container } from "components/Grid";

const Footer = styled.footer`
  padding: 1rem 0;
  background-color: #f5f5f5;
  font-size: 1.4rem;
  font-weight: 300;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ListItem = styled.li`
  flex: 0 1 50%;
  max-width: 50%;
  text-align: center;
  padding: 1rem;

  a {
    font-size: 1.4rem;
    color: #231f20;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @media (min-width: 576px) {
    flex: 0 1 25%;
    max-width: 25%;
  }
`;

const Index = ({ email }) => {
  return (
    <Footer>
      <Container>
        <List>
          <ListItem>
            <Link href="/about">
              <a>About</a>
            </Link>
          </ListItem>
          <ListItem>
            <a href={`mailto:${email}`} target="_blank">
              Contact Us
            </a>
          </ListItem>
          <ListItem>
            <Link href="/affiliates">
              <a>Affiliates</a>
            </Link>
          </ListItem>
          <ListItem>
            <Link href="/terms">
              <a>Terms / Privacy</a>
            </Link>
          </ListItem>
        </List>
      </Container>
    </Footer>
  );
};

export default Index;
