import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Router from "next/router";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import DownArrow from "./down-arrow.svg";

const Container = styled.div`
  position: relative;
`;

const Toggle = styled.button`
  position: relative;
  padding: 0.3rem 1rem;
  border-width: 1px;
  border-style: solid solid none;
  border-color: ${(props) => (props.open ? "#e7e7e7" : "transparent")};
  background-color: #fff;
  transition: border-color 0.15s ease-out;
  text-align: left;
  border-radius: 0.5rem 0.5rem 0 0;

  svg {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: 1.2rem;
    fill: currentColor;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.primary};
  font-size: 1.2rem;
`;

const Text = styled.div`
  position: relative;
  padding-right: 2rem;
  color: ${(props) => props.theme.palette.primary};
  font-weight: bold;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 480px) {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    border-top: 0;
    background-color: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    transition: opacity 0.15s ease-out, visibility 0.15s ease-out;
    opacity: ${(props) => {
      switch (props.state) {
        case "entering":
        case "entered":
          return 1;
        case "exiting":
        case "exited":
          return 0;
      }
    }};
    visibility: ${(props) => {
      switch (props.state) {
        case "entering":
        case "entered":
          return "visible";
        case "exiting":
        case "exited":
          return "hidden";
      }
    }};
  }
`;

const ListItem = styled.li`
  flex: 1 1 auto;
  display: inline-block;
  text-align: center;

  &:not(:first-child) {
    border-left: 1px solid #ccc;
  }

  a {
    display: block;
    padding: 0.5rem;
    color: #666;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 480px) {
    display: block;

    &:not(:first-child) {
      border-left: 0;
      border-top: 1px solid #ccc;
    }
  }
`;

export const ExploreMenu = (props) => (
  <List {...props}>
    <ListItem>
      <Link href="/[category]" as="/home-and-garden">
        <a>Home</a>
      </Link>
    </ListItem>
    <ListItem>
      <Link href="/[category]" as="/electronics">
        <a>Electronics</a>
      </Link>
    </ListItem>
    <ListItem>
      <Link href="/[category]" as="/sports-and-fitness">
        <a>Sports</a>
      </Link>
    </ListItem>
  </List>
);

const Explore = () => {
  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClick = (e) => {
    if (ref.current && ref.current.contains(e.target)) return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);

  Router.events.on("routeChangeComplete", () => open && setOpen(false));

  return (
    <Container ref={ref}>
      <Toggle open={open} type="button" onClick={handleToggle}>
        <Title>explore</Title>
        <Text>
          All Quizzes <DownArrow />
        </Text>
      </Toggle>
      <Transition in={open} timeout={150}>
        {(state) => <ExploreMenu state={state} />}
      </Transition>
    </Container>
  );
};

export default Explore;
