import React, { useState, useEffect, useRef } from "react";
import Router from "next/router";
import styled from "styled-components";
import { Container, Row, Col } from "components/Grid";
import ScreenReader from "components/ScreenReader";
import Logo from "./Logo";
import Search from "./Search";
import Explore from "./Explore";
import PastResults from "./PastResults";
import MobileMenu from "./MobileMenu";
import MenuIcon from "./hamburger-icon.svg";

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(#000, 0.1);
  border-bottom: 1px solid #e7e7e7;
`;

const Wrap = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;

const MenuToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;

  svg {
    width: 3rem;
  }
`;

const ColMenu = styled(Col)`
  @media (min-width: 480px) {
    display: none;
  }
`;

const ColLogo = styled(Col)`
  flex-grow: 1;
  padding: 0;

  @media (min-width: 480px) {
    flex-grow: 0;
    padding: 0 1.5rem;
  }
`;

const ColSearch = styled(Col)`
  display: none;

  @media (min-width: 480px) {
    display: block;
  }
`;

const ColExplore = styled(Col)`
  display: none;

  @media (min-width: 480px) {
    display: block;
    border-left: 1px solid ${(props) => props.theme.palette.border};
  }
`;

const ColPastResults = styled(Col)`
  flex-basis: 8rem;

  @media (min-width: 480px) {
    flex-basis: 0;
    border-left: 1px solid ${(props) => props.theme.palette.border};
  }
`;

const Spacer = styled.div`
  flex: 1 1 auto;
  display: none;

  @media (min-width: 480px) {
    display: block;
  }
`;

const HeaderIndex = (props) => {
  const componentIsMounted = useRef(true);

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    []
  );

  Router.events.on(
    "routeChangeStart",
    () => componentIsMounted.current && menuOpen && setMenuOpen(false)
  );

  return (
    <Header>
      <Wrap>
        <Row wrap="nowrap" alignItems="center">
          <ColMenu>
            <MenuToggle onClick={() => setMenuOpen(!menuOpen)}>
              <ScreenReader>Menu</ScreenReader>
              <MenuIcon />
            </MenuToggle>
          </ColMenu>

          <ColLogo>
            <Logo hide={searchOpen} />
          </ColLogo>

          <Spacer />

          <ColSearch>
            <Search
              open={searchOpen}
              onToggle={() => setSearchOpen(!searchOpen)}
            />
          </ColSearch>

          <ColExplore>
            <Explore />
          </ColExplore>

          <ColPastResults>
            <PastResults onOpen={props.onOpen} />
          </ColPastResults>
        </Row>
      </Wrap>

      <MobileMenu open={menuOpen} />
    </Header>
  );
};

export default HeaderIndex;
