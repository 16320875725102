import React from "react";
import Link from "next/link";
import styled from "styled-components";
import ChoicesChoicesLogo from "./choices-choices-logo.svg";

const Logo = styled.div`
  max-width: 14rem;
  margin: 0 auto;

  @media (min-width: 480px) and (max-width: 639px) {
    display: ${(props) => (props.hide ? "none" : "block")};
  }

  a {
    display: block;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

const LogoIndex = ({ hide }) => {
  return (
    <Logo hide={hide}>
      <Link href="/">
        <a aria-label="Back to home">
          <ChoicesChoicesLogo />
        </a>
      </Link>
    </Logo>
  );
};

export default LogoIndex;
