import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import NextImage from "next/image";
import get from "lodash/get";
import styled from "styled-components";
import { storage } from "lib/firebase";
import amazonLink from "lib/amazonLink";
import { useConverted } from "lib/user/context";
import AmazonButton from "components/AmazonButton";

const Title = styled.h3`
  font-size: 2rem;
  margin-bottom: 0;
  text-align: center;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 480px) {
    flex-wrap: nowrap;
  }
`;

const Image = styled.div`
  flex: 1 1 100%;
  max-width: 100%;
  text-align: center;

  @media (min-width: 480px) {
    flex: 0 0 15rem;
    width: 15rem;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  max-width: 100%;

  @media (min-width: 480px) {
    padding-left: 3rem;
  }
`;

const ResultsButton = styled.a`
  display: block;
  padding: 1rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #666;
  background-color: #fff;
  border: 1px solid #666;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  @media (hover: hover) {
    &:hover,
    &:focus {
      color: #fff;
      background-color: #666;
    }
  }
`;

const Separator = styled.div`
  position: relative;
  display: block;
  margin: 1.5rem auto;
  overflow: visible;
  width: 15rem;
  height: 1px;
  background-color: #cac8c8;
  color: #666;

  &::before {
    content: "or";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 30px;
    height: auto;
    font-size: 1.6rem;
    line-height: 1.25;
    text-align: center;
    background-color: #fff;
  }
`;

const ResultIndex = (props) => {
  const [_, setConverted] = useConverted();

  const isMounted = useRef(true);
  const [image, setImage] = useState(null);

  const fetchImage = async () => {
    const ref = storage
      .ref()
      .child(`products/${props.products.best.id}-@thumb-600`);

    const src = await ref.getDownloadURL();
    if (isMounted.current) setImage(src);
  };

  useEffect(() => {
    if (props.products.best.image) fetchImage();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const amazon = get(props, `products.best.prices.${props.country}`, null);

  // Set user's converted state
  const handleConversion = () => setConverted(true);

  return (
    <>
      <Title>{props.products.best.name}</Title>

      <Row>
        <Image>
          {image && (
            <NextImage
              src={image}
              alt={props.products.best.name}
              width={150}
              height={150}
              objectFit="contain"
            />
          )}
        </Image>

        <Content>
          <Link
            href="/quiz/[slug]/result/[id]"
            as={`/quiz/${props.quiz.slug}/result/${props.id}`}
            passHref
          >
            <ResultsButton onClick={props.onClose}>
              View Results Page
            </ResultsButton>
          </Link>

          {amazon && (
            <>
              <Separator />

              <AmazonButton
                href={amazonLink(amazon["LINK 1"], props.tag)}
                target="_blank"
                rel="sponsored"
                onClick={handleConversion}
              >
                {amazon["Text 1"]}
              </AmazonButton>
            </>
          )}
        </Content>
      </Row>
    </>
  );
};

export default ResultIndex;
