import React, { useState } from "react";
import fetch from "isomorphic-unfetch";
import styled from "styled-components";
import { darken } from "polished";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  flex: 1 1 100%;
  display: block;
  padding: 0.5rem 0;
  color: #666;

  @media (min-width: 480px) {
    flex: 0 1 auto;
  }
`;

const FormGroup = styled.div`
  flex: 1 1 100%;
  position: relative;

  @media (min-width: 480px) {
    flex: 1 1 auto;
    padding-left: 1rem;
  }
`;

const TextField = styled.input`
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem;
  padding-right: 8rem;
  height: 4rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.palette.main};
  border: 1px solid ${(props) => props.theme.palette.border};
  border-radius: 0.5rem;
  outline: none;
  appearance: none;
  transition: border-color 0.2s ease-out;

  &:focus {
    border-color: #ccc;
  }
`;

const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  height: 4rem;
  color: #fff;
  background-color: ${(props) => props.theme.palette.primary};
  border-radius: 0 0.5rem 0.5rem 0;
  transition: background-color 0.15s ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.palette.primary)};
    }
  }
`;

const SentText = styled.p`
  color: ${(props) => props.theme.palette.primary};
  font-weight: 700;
`;

const EmailForm = (props) => {
  const [email, setEmail] = useState("");
  const [sent, emailSent] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const results = props.results.map((result) => {
      return `
        <b>Quiz:</b> ${result.quiz.name} <br />
        <b>Recommended Product:</b> ${result.products.best.name} <br />
        <b>Results Page:</b> https://${window.location.host}/quiz/${result.quiz.slug}/result/${result.id} <br />
        <br />
      `;
    });

    const data = {
      to: email,
      from: "ChoicesChoices <results@choiceschoices.com>",
      subject: "Your Past Results From ChoicesChoices",
      html:
        `Hi, you have asked us to email you with your past results, so here they are: <br /><br />` +
        results.join(""),
    };

    setEmail("");
    emailSent(true);

    await fetch(`${process.env.NEXT_PUBLIC_API}/email`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  if (sent) return <SentText>We have sent you an email</SentText>;

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Label htmlFor="prEmail">Send this list to yourself.</Label>

      <FormGroup>
        <TextField
          id="prEmail"
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
        />

        <Button type="submit">Send</Button>
      </FormGroup>
    </Form>
  );
};

export default EmailForm;
