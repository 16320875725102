import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import Button from "components/Button";
import { useUser } from "lib/user/context";
import TickIcon from "./icon-tick.svg";

const Container = styled.div`
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 50%;
  backface-visibility: hidden;
  transform: translateZ(0) translateX(-50%);
  width: 100%;
  max-width: 76.8rem;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 3rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #fff;
  box-shadow: -1px -1px 1px 0 #e7e7e7;
  border: solid 1px #e7e7e7;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const Text = styled.div`
  flex: 1 1 100%;
  max-width: 100%;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 1px;

  @media (min-width: 768px) {
    flex: 1 1 auto;
    padding-right: 5rem;
  }
`;

const Actions = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 30rem;
  margin: 0 auto;
  padding-top: 2rem;

  @media (min-width: 768px) {
    flex: 0 0 15rem;
    display: block;
    max-width: 15rem;
    padding-top: 0;
  }
`;

const AcceptButton = styled(Button)`
  width: 100%;
  min-width: 0;
  padding: 1.2rem 1rem;
  font-size: 1.2rem;

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.6rem;
    margin-top: -0.2rem;
  }

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const cookies = new Cookies();

const CookieBanner = (props) => {
  const user = useUser();

  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    if (
      !cookies.get("cookieAccept") &&
      user &&
      (user.in_eu || user.country === "UK")
    ) {
      setAccepted(false);
    }
  }, [user]);

  const handleAccept = () => {
    cookies.set("cookieAccept", true, { path: "/" });
    setAccepted(true);
  };

  if (accepted) return null;

  return (
    <Container>
      <Text>{props.text}</Text>
      <Actions>
        <AcceptButton onClick={handleAccept}>
          <TickIcon />
          CONTINUE
        </AcceptButton>
      </Actions>
    </Container>
  );
};

export default CookieBanner;
