import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import ScreenReader from "components/ScreenReader";
import { useUser } from "lib/user/context";
import usePastResults from "./usePastResults";
import EmailForm from "./EmailForm";
import Results from "./Results";

import CloseIcon from "./close-icon.svg";

const Container = styled.aside`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 48rem;
  background-color: #fff;
  transition: transform 0.2s ease-out;
  transform: ${(props) => {
    switch (props.state) {
      case "entering":
      case "entered":
        return "translateX(0)";
      case "exiting":
      case "exited":
        return "translateX(100%)";
    }
  }};
`;

const Close = styled.button`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;

  svg {
    width: 1.6rem;
    fill: #999;
    transition: fill 0.15s ease-out;
  }

  &:hover svg {
    fill: #333;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 100%;
  padding: 1.5rem;
`;

const Heading = styled.div`
  flex: 0 1 auto;
`;

const Title = styled.h2`
  font-size: 2.2rem;
  line-height: 1;
  font-weight: normal;

  @media (min-width: 480px) {
    margin-bottom: 2rem;
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  opacity: ${(props) => {
    switch (props.state) {
      case "entering":
      case "entered":
        return 1;
      case "exiting":
      case "exited":
        return 0;
    }
  }};
  visibility: ${(props) => {
    switch (props.state) {
      case "entering":
      case "entered":
        return "visible";
      case "exiting":
      case "exited":
        return "hidden";
    }
  }};
`;

const PastResults = ({ open, onClose }) => {
  const user = useUser();

  const results = usePastResults(user);

  const ref = useRef(null);

  useEffect(
    () => () => {
      clearAllBodyScrollLocks();
    },
    []
  );

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current);
      } else {
        enableBodyScroll(ref.current);
      }
    }
  }, [open]);

  return (
    <Transition in={open} timeout={200}>
      {(state) => (
        <React.Fragment>
          <Container state={state}>
            <Close onClick={onClose}>
              <ScreenReader>Close Menu</ScreenReader>
              <CloseIcon />
            </Close>

            <Content>
              <Heading>
                <Title>Past Results</Title>

                {results && <EmailForm results={results} />}
              </Heading>

              {!results ? (
                "Loading..."
              ) : (
                <Results
                  ref={ref}
                  onClose={onClose}
                  results={results}
                  user={user}
                />
              )}
            </Content>
          </Container>

          <Overlay state={state} onClick={onClose} />
        </React.Fragment>
      )}
    </Transition>
  );
};

export default PastResults;
