import React from "react";
import styled from "styled-components";
import { darken } from "polished";

const Link = styled.a`
  display: block;
  padding: 1rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #fff;
  background-color: #eeb500;
  transition: background-color 0.2s ease-out;

  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: ${darken(0.1, "#eeb500")};
    }
  }
`;

const AmazonButton = ({ children, ...props }) => {
  return <Link {...props}>{children}</Link>;
};

export default AmazonButton;
