import React from "react";
import styled from "styled-components";
import Result from "./Result";

const Overflow = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
`;

const List = styled.ul``;

const ListItem = styled.li`
  padding: 1.5rem 0;

  &:not(:first-child) {
    border-top: 1px solid #ddd;
  }
`;

const Results = React.forwardRef((props, ref) => {
  return (
    <Overflow ref={ref}>
      <List>
        {props.results.map((result) => (
          <ListItem key={`past-result-${result.id}`}>
            <Result
              onClose={props.onClose}
              country={props.user.country}
              {...result}
            />
          </ListItem>
        ))}
      </List>
    </Overflow>
  );
});

export default Results;
