import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: ${props => props.wrap || "wrap"};
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  align-items: ${props => props.alignItems || "normal"};
  justify-content: ${props => props.justifyContent || "normal"};
`;

export const Col = styled.div`
  flex: 0 1 auto;
  max-width: 100%;
  padding: 0 1.5rem;
`;

export const FullContent = styled.div`
  min-height: calc(100vh - 16.8rem);

  @media (min-width: 480px) {
    min-height: calc(100vh - 16.4rem);
  }

  @media (min-width: 576px) {
    min-height: calc(100vh - 12.5rem);
  }

  @media (min-width: 768px) {
    min-height: calc(100vh - 13.5rem);
  }
`;
