import React, { useState } from "react";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { darken } from "polished";

import ScreenReader from "components/ScreenReader";
import SearchIcon from "./search-icon.svg";

const Form = styled.form`
  position: relative;

  @media (min-width: 480px) {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    max-width: 20rem;
  }
`;

const SearchField = styled.input`
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 5.5rem 1rem 1rem;
  height: 4rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.palette.main};
  border: 1px solid ${(props) => props.theme.palette.border};
  border-radius: 0.5rem;
  outline: none;
  appearance: none;
  transition: border-color 0.2s ease-out;

  &:focus {
    border-color: #ccc;
  }

  @media (min-width: 480px) {
    display: ${(props) => (props.open ? "block" : "none")};
    position: absolute;
    z-index: 1;
    top: 0;
    right: 100%;
    width: 19rem;
    width: calc(100vw - 29.2rem);
    padding: 1rem;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  @media (min-width: 768px) {
    width: 19rem;
  }
`;

const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 4rem;
  background-color: ${(props) => props.theme.palette.primary};
  border-radius: 0 0.5rem 0.5rem 0;
  transition: background-color 0.15s ease-out;

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.palette.primary)};
    }
  }

  svg {
    width: 1.8rem;
  }

  @media (min-width: 480px) {
    position: relative;
    right: auto;
    top: auto;
    border-radius: ${(props) => (props.open ? "0 0.5rem 0.5rem 0" : "0.5rem")};
  }
`;

const SearchIndex = (props) => {
  const router = useRouter();

  const [searchTerm, setSearchTerm] = useState("");

  const isTabletUp = useMediaQuery({
    query: "(min-width: 480px)",
  });

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggle = (e) => {
    if (isTabletUp) {
      if (!props.open || searchTerm.trim().length < 1) {
        e.preventDefault();
        props.onToggle();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const term = searchTerm.trim();

    if (term.length > 0) {
      router.push("/search/[term]", `/search/${term}`);
    }
  };

  return (
    <Form action="/search" method="get" onSubmit={handleSubmit} noValidate>
      <SearchField
        type="search"
        name="search"
        value={searchTerm}
        onChange={handleChange}
        aria-label="Search site"
        placeholder="Search..."
        open={props.open}
      />

      <Button type="submit" open={props.open} onClick={handleToggle}>
        <ScreenReader>Search</ScreenReader>
        <SearchIcon />
      </Button>
    </Form>
  );
};

export default SearchIndex;
