import React from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import Search from "../Search";
import { ExploreMenu } from "../Explore";

const Container = styled.nav`
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  transition: opacity 0.15s ease-out, visibility 0.15s ease-out;
  opacity: ${(props) => {
    switch (props.state) {
      case "entering":
      case "entered":
        return 1;
      case "exiting":
      case "exited":
        return 0;
    }
  }};
  visibility: ${(props) => {
    switch (props.state) {
      case "entering":
      case "entered":
        return "visible";
      case "exiting":
      case "exited":
        return "hidden";
    }
  }};

  @media (min-width: 480px) {
    display: none;
  }
`;

const MobileMenuIndex = ({ open }) => {
  return (
    <Transition in={open} timeout={150}>
      {(state) => (
        <Container state={state}>
          <ExploreMenu />

          <Search />
        </Container>
      )}
    </Transition>
  );
};

export default MobileMenuIndex;
