import styled from "styled-components";
import { darken } from "polished";
import BookmarkIcon from "./bookmark-icon.svg";
import { useUser } from "lib/user/context";
import { get } from "lodash";

const Button = styled.button`
  position: relative;
  display: block;
  width: 3rem;
  margin: 0 auto;

  svg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    fill: ${(props) => props.theme.palette.primary};
    transition: fill 0.15s ease-out;
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      svg {
        fill: ${(props) => darken(0.1, props.theme.palette.primary)};
      }
    }
  }
`;

const Number = styled.div`
  position: absolute;
  z-index: 2;
  top: ${(props) => (props.large ? "0.8rem" : "0.4rem")};
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: ${(props) => (props.large ? "1.6rem" : "2rem")};
  font-weight: bold;
`;

const PastResults = ({ onOpen }) => {
  const user = useUser();

  const count = get(user, "resultsCount", null);

  return (
    <Button type="button" onClick={onOpen}>
      <BookmarkIcon />
      <Number large={count > 99}>{count}</Number>
    </Button>
  );
};

export default PastResults;
