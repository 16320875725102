import React, { useState } from "react";
import Head from "next/head";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import PastResults from "components/PastResults";
import { FullContent } from "components/Grid";
import Header from "./Header";
import Footer from "./Footer";
import CookieBanner from "./CookieBanner";
import { useUser } from "lib/user/context";

const Container = styled.div`
  max-width: 100%;
`;

const Layout = (props) => {
  const user = useUser();
  const { open, onOpen, onClose } = useDrawer();

  return (
    <React.Fragment>
      <Head>
        <title>{props.seo.title}</title>

        {!isEmpty(props.seo.description) && (
          <meta name="description" content={props.seo.description} />
        )}
      </Head>

      <Container>
        <Header onOpen={onOpen} />

        <FullContent>{props.children}</FullContent>

        <Footer email={props.email} />
      </Container>

      <PastResults open={open} onClose={onClose} user={user} />

      <CookieBanner text={props.cookiePolicy} />
    </React.Fragment>
  );
};

export default Layout;

function useDrawer() {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return { open, onOpen, onClose };
}
